// ** Initial State
const initialState = {
  allSections: [],
  selectedSection: null,
  isLoading: false
}

const legalSections = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_LEGAL_SECTIONS':
      return { ...state, allSections: action.data }
    case 'GET_LEGAL_SECTION':
      return { ...state, selectedSection: action.selectedSection }
    case 'ADD_LEGAL_SECTION':
      return { ...state }
    case 'UPDATE_LEGAL_SECTION':
      return { ...state }
    case 'DELETE_SECTION':
      return { ...state }
    case 'SHOW_LOADING':
        return { ...state, isLoading: action.payload }       
    default:
      return { ...state }
  }
}
export default legalSections
