// ** Initial State
const initialState = {
  allQuestionsData: [],
  selectedQuestion: null,
  selectedAnswer: null,
  selectedQuestionAnswer: null,
  isLoading: false
}

const questions = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_QUESTIONS_DATA':
      return { ...state, allQuestionsData: action.data }
    case 'GET_QUESTION':
      return { ...state, selectedQuestion: action.selectedQuestion }
      case 'GET_QUESTION_ANSWER':
      return { ...state, selectedQuestionAnswer: action.selectedQuestionAnswer }
      case 'GET_ANSWER':
        return { ...state, selectedAnswer: action.selectedAnswer }
      case 'ADD_QUESTION':
      return { ...state }
      case 'ADD_MULTI_QUESTION':
        return { ...state }
      case 'UPDATE_QUESTION':
      return { ...state }
      case 'UPDATE_ANSWER':
        return { ...state }
      case 'DELETE_QUESTION':
      return { ...state }
      case 'ADD_ANSWER':
      return { ...state }
      case 'DELETE_ANSWER':
      return { ...state }
      case 'SHOW_LOADING':
      return { ...state, isLoading: action.payload }
    default:
      return { ...state }
  }
}
export default questions
