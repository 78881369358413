// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from '@src/views/user/store/reducer'
import subscribers from '@src/views/subscribers/store/reducer'
import invoiceReducer from '@src/views/apps/invoice/store/reducer'
import categories from '@src/views/categories/store/reducer'
import exercises from '@src/views/exercises/store/reducer'
import plans from '@src/views/plans/store/reducer'
import notifications from '@src/views/notifications/store/reducer'
import questions from '@src/views/questions/store/reducer'
import programs from '@src/views/programs/store/reducer'
import userProgram from '@src/views/program-ui/store/reducer'
import reports from '@src/views/reports/store/reducer'
import legalSections from '@src/views/legal/store/reducer'

const rootReducer = combineReducers({
  auth,
  users,
  subscribers,
  navbar,
  layout,
  invoiceReducer,
  categories,
  exercises,
  plans,
  notifications,
  questions,
  programs,
  userProgram,
  reports,
  legalSections
})

export default rootReducer
