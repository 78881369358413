// ** Initial State
const initialState = {
  allProgramsData: [],
  selectedProgram: null,
  isLoading: false
}

const programs = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PROGRAMS_DATA':
      return { ...state, allProgramsData: action.data }
    case 'GET_PROGRAM':
      return { ...state, selectedProgram: action.selectedProgram }
    case 'ADD_PROGRAM':
      return { ...state }
      case 'UPDATE_PROGRAM':
      return { ...state }
    case 'DELETE_PROGRAM':
      return { ...state }
      case 'SHOW_LOADING':
        return { ...state, isLoading: action.payload }       
    default:
      return { ...state }
  }
}
export default programs
