// ** Initial State
const initialState = {
  allData: [],
  selectedUser: null,
  selectedUserPlan: null,
  selectedUserPlanHistory:[],
  selectedUserPrograms: [],
  selectedProgram: null,
  isLoading: false
}

const subscribers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_SUBSCRIBER':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
      case 'GET_USER_ACTIVE_PLAN':
      return { ...state, selectedUserPlan: action.selectedUserPlan }
      case 'GET_USER_PLAN_HISTORY':
        return { ...state, selectedUserPlanHistory: action.selectedUserPlanHistory }
        case 'GET_USER_PROGRAMS':
          return { ...state, selectedUserPrograms: action.selectedUserPrograms }
          case 'GET_USER_PROGRAM':
            return { ...state, selectedProgram: action.selectedProgram }
          case 'SHOW_LOADING':
            return { ...state, isLoading: action.payload }      
    default:
      return { ...state }
  }
}
export default subscribers
