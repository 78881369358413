// ** Initial State
const initialState = {
  allEXData: [],
  data: [],
  total: 1,
  params: {},
  selectedExercise: null,
  isLoading: false
}

const exercises = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_EXERCISES':
      return { ...state, allEXData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_EXERCISE':
      return { ...state, selectedExercise: action.selectedExercise }
    case 'ADD_EXERCISE':
      return { ...state }
    case 'DELETE_EXERCISE':
      return { ...state }
    case 'DELETE_VIDEO':
      return { ...state }  
      case 'SHOW_LOADING':
        return { ...state, isLoading: action.payload }
    default:
      return { ...state }
  }
}
export default exercises
