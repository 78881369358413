// ** Initial State
const initialState = {
  defaultQuestion: null,
  nextQuestion: null,
  isLoading: false,
  Error:null
}

const userProgram = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DEFAULT_QUESTION':
      return { ...state, defaultQuestion: action.data }
      case 'GET_NEXT_QUESTION':
      return { ...state, nextQuestion: action.data, defaultQuestion: null }
      case 'SHOW_LOADING':
      return { ...state, isLoading: action.payload }
      case 'ERROR':
      return { ...state, Error: action.data }       
    default:
      return { ...state }
  }
}
export default userProgram
