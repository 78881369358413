// ** Initial State
const initialState = {
  allNotificationsData: [],
  data: [],
  total: 1,
  params: {},
  selectedNotification: null,
  isLoading: false
}

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_NOTIFICATIONS':
      return { ...state, allNotificationsData: action.data }
    case 'GET_NOTIFICATION':
      return { ...state, selectedNotification: action.selectedNotification }
    case 'ADD_NOTIFICATION':
      return { ...state }
      case 'SEND_NOTIFICATION':
        return { ...state }
    case 'DELETE_NOTIFICATION':
      return { ...state }
      case 'SHOW_LOADING':
        return { ...state, isLoading: action.payload }    
    default:
      return { ...state }
  }
}
export default notifications
