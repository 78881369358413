// ** Initial State
const initialState = {
  allCatData: [],
  allActiveCategoryData: [],
  selectedCategory: null,
  isLoading: false
}

const categories = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_CATEGORIES':
    return { ...state, allCatData: action.data }
    case 'GET_ALL_ACTIVE_CATEGORIES':
    return { ...state, allActiveCategoryData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CATEGORY':
      return { ...state, selectedCategory: action.selectedCategory }
    case 'ADD_CATEGORY':
      return { ...state }
    case 'DELETE_CATEGORY':
      return { ...state }
      case 'SHOW_LOADING':
        return { ...state, isLoading: action.payload }   
    default:
      return { ...state }
  }
}
export default categories
