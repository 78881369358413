// ** Initial State
const initialState = {
  allUsers: [],
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  isLoading: false,
  UserError: null
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_USERS':
      return { ...state, allUsers: action.data }
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state }
      case 'REGISTER_USER':
      return { ...state }
      case 'UPDATE_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
      case 'FORGOT_PASSWORD':
      return { ...state }
      case 'SHOW_LOADING':
      return { ...state, isLoading: action.payload }
      case 'USER_ERROR':
        return { ...state, UserError: action.data }  
    default:
      return { ...state }
  }
}
export default users
