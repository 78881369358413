// ** Initial State
const initialState = {
  totalSales: [],
  totalSubscription: [],
  totalAssignedPrograms: [],
  totalCompletedPrograms: [],
  latestSubscribers: [],
  monthSales: [],
  monthSubscriptions: [],
  monthAssignedPrograms: [],
  monthCompletedPrograms: [],
  isLoading: false,
  isLoadingProgram: false,
  isLoadingSales: false
}

const reports = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LATEST_SUBSCRIBERS':
      return { ...state, latestSubscribers: action.data }
    case 'GET_TOTAL_SALES':
      return { ...state, totalSales: action.data }
    case 'GET_MONTH_SALES':
      return { ...state, monthSales: action.data }  
    case 'GET_TOTAL_SUBSCRIPTIONS':
      return { ...state, totalSubscription: action.data }
    case 'GET_MONTH_SUBSCRIPTIONS':
      return { ...state, monthSubscriptions: action.data }
    case 'GET_TOTAL_ASSIGNED_PROGRAMS':
      return { ...state, totalAssignedPrograms: action.data } 
    case 'GET_MONTH_PROGRAMS_ASSIGNED':
      return { ...state, monthAssignedPrograms: action.data }       
    case 'GET_TOTAL_COMPLETED_PROGRAMS':
      return { ...state, totalCompletedPrograms: action.data }
    case 'GET_MONTH_COMPLEDTED_PROGRAMS':
      return { ...state, monthCompletedPrograms: action.data }                         
    case 'SHOW_LOADING':
      return { ...state, isLoading: action.payload }
    case 'SHOW_LOADING_PROGRAM':
      return { ...state, isLoadingProgram: action.payload }
    case 'SHOW_LOADING_SALES':
      return { ...state, isLoadingSales: action.payload }      
    default:
      return { ...state }
  }
}
export default reports
