// ** Initial State
const initialState = {
  allPlansData: [],
  selectedPlan: null,
  allVouchersData: [],
  selectedVoucher: null,
  isLoading: false
}

const plans = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PLANS_DATA':
      return { ...state, allPlansData: action.data }
    case 'GET_PLAN':
      return { ...state, selectedPlan: action.selectedPlan }
    case 'ADD_PLAN':
      return { ...state }
    case 'UPDATE_PLAN':
      return { ...state }
    case 'DELETE_PLAN':
      return { ...state }
    case 'SHOW_LOADING':
        return { ...state, isLoading: action.payload }     
    case 'GET_ALL_VOUCHERS':
          return { ...state, allVouchersData: action.VoucherData }
    case 'GET_VOUCHER':
          return { ...state, selectedVoucher: action.selectedVoucher }
    case 'ADD_VOUCHER':
          return { ...state }      
    case 'DELETE_VOUCHER':
            return { ...state }          
    default:
      return { ...state }
  }
}
export default plans
